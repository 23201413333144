export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71')
];

export const server_loads = [];

export const dictionary = {
		"/(dashboard)": [29,[3]],
		"/(public)/404": [67,[13]],
		"/(dashboard)/activity": [30,[3,4]],
		"/(dashboard)/activity/quickbooks": [31,[3,4]],
		"/(dashboard)/activity/scheduled": [32,[3,4]],
		"/(dashboard)/announcements": [33,[3]],
		"/(backoffice)/backoffice": [16,[2]],
		"/(backoffice)/backoffice/assets": [17,[2]],
		"/(backoffice)/backoffice/mining": [18,[2]],
		"/(backoffice)/backoffice/mining/credits": [19,[2]],
		"/(backoffice)/backoffice/mining/workers2": [21,[2]],
		"/(backoffice)/backoffice/mining/workers": [20,[2]],
		"/(backoffice)/backoffice/newcustomer": [22,[2]],
		"/(backoffice)/backoffice/playground": [23,[2]],
		"/(backoffice)/backoffice/playground/mpctest": [24,[2]],
		"/(backoffice)/backoffice/reports": [25,[2]],
		"/(backoffice)/backoffice/rsa": [26,[2]],
		"/(backoffice)/backoffice/transactions": [27,[2]],
		"/(backoffice)/backoffice/users": [28,[2]],
		"/(public)/clear": [68,[13,14]],
		"/(dashboard)/deposit": [34,[3,5]],
		"/(dashboard)/deposit/coinbase": [35,[3,5]],
		"/(public)/farewell": [69,[13]],
		"/(dashboard)/helpdesk": [36,[3]],
		"/(dashboard)/invoices": [37,[3]],
		"/(public)/invoice": [70,[13]],
		"/(login)/login": [65,[11]],
		"/(dashboard)/noaccess": [38,[3]],
		"/(dashboard)/pay": [39,[3]],
		"/(login)/register": [66,[12]],
		"/(dashboard)/request": [40,[3]],
		"/(dashboard)/settings": [41,[3,6]],
		"/(dashboard)/settings/accounts": [42,[3,6]],
		"/(dashboard)/settings/billing": [43,[3,6]],
		"/(dashboard)/settings/customers": [44,[3,6]],
		"/(dashboard)/settings/gas": [45,[3,6,7]],
		"/(dashboard)/settings/gas/details": [46,[3,6,7]],
		"/(dashboard)/settings/gateway": [47,[3,6,8]],
		"/(dashboard)/settings/gateway/fees": [48,[3,6,8]],
		"/(dashboard)/settings/gateway/gatewaysettings": [49,[3,6,8]],
		"/(dashboard)/settings/notifications": [50,[3,6]],
		"/(dashboard)/settings/personal/appearance": [51,[3,6,9]],
		"/(dashboard)/settings/personal/user": [52,[3,6,9]],
		"/(dashboard)/settings/quickbooks": [53,[3,6]],
		"/(dashboard)/settings/recipients": [54,[3,6]],
		"/(dashboard)/settings/security": [55,[3,6,10]],
		"/(dashboard)/settings/security/access": [56,[3,6,10]],
		"/(dashboard)/settings/security/activity": [57,[3,6,10]],
		"/(dashboard)/settings/security/approval": [58,[3,6,10]],
		"/(dashboard)/settings/security/devices": [59,[3,6,10]],
		"/(dashboard)/settings/transferrules": [60,[3,6]],
		"/(dashboard)/settings/users": [61,[3,6]],
		"/(dashboard)/transfer": [62,[3]],
		"/(dashboard)/uitest": [63,[3]],
		"/v0/[slug]": [71,[15]],
		"/(dashboard)/withdraw": [64,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';